import React, { useState } from 'react';
import logo from '../assets/full-logo.png';

import { FaWhatsapp, FaFacebook, FaPhone, FaEnvelope } from 'react-icons/fa';

function getFormData(form) {
  const elements = form.elements;
  let honeypot;

  const fields = Object.keys(elements).filter((k) => {
    if (elements[k].name === "honeypot") {
      honeypot = elements[k].value;
      return false;
    }
    return true;
  }).map((k) => {
    if(elements[k].name !== undefined) {
      return elements[k].name;
    } else if(elements[k].length > 0){
      return elements[k].item(0).name;
    }
  }).filter((item, pos, self) => {
    return self.indexOf(item) === pos && item;
  });

  const formData = {};
  fields.forEach((name) => {
    const element = elements[name];

    formData[name] = element.value;

    if (element.length) {
      const data = [];
      for (let i = 0; i < element.length; i++) {
        const item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses";
  formData.formGoogleSendEmail = form.dataset.email || "";

  return {data: formData, honeypot: honeypot};
}

function Contact() {
  const [buttonText, setButtonText] = useState('Submit');

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = getFormData(form);
    const data = formData.data;

    if (formData.honeypot) {
      return false;
    }

    setButtonText('Please Wait...');

    const url = form.action;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        form.reset();
        setButtonText('Submit');
      }
    };

    const encoded = Object.keys(data).map((k) => {
      return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');

    xhr.send(encoded);
  }

  return (
    <div className="container py-5">
      <div>
          <img src={logo} alt="Integrity Solutions & Logistics" />
          <div className='py-3'></div>
      </div>
      <section className="text-center">
        <div className="container">
          <h6>CONTACT US</h6>
        </div>
      </section>
      <section className="contact-form">
      <div className="container">
          <div className="row">
            
            <div className="col-lg-6 mt-1">
              <h3>Send Us a Message</h3>
              <p className='mt-4'></p>
              <form
                onSubmit={handleSubmit}
                method="post"
                action="sendMail.php"
              >
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Enter the subject"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="5"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={buttonText === 'Please Wait...'}>{buttonText}</button>
                <p className='mt-5'></p>
              </form>
            </div>
            <div className="col-lg-6 mt-1 contact">
              <h3>Visit Us</h3>
              <p className=' text-content'>We are located at:</p>
              <p className=' text-content'>10 Dundas Street, Freetown, Sierra Leone</p>
              
              <iframe loading="lazy" src="https://maps.google.com/maps?q=Freetown%20Sierra%20Leone%2C10%20Dundas%20Street&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title="Freetown Sierra Leone,10 Dundas Street" aria-label="Freetown Sierra Leone,10 Dundas Street"></iframe>
              <p className='mt-4'></p>
              <p className=' text-content'>Branch Office:</p>
              <p className=' text-content'>Freetown International Airport</p>
              <h3>Contact Information</h3>
              <p className=' text-content'>
                <a href="mailto:info@integritysolutionsc.com" className='no-style-dark'><FaEnvelope size={20} className='contact-icon' />info@integritysolutionsc.com</a>
              </p>
              <p className=' text-content'>
                <a href="tel:+23278195584"><FaPhone size={20} className='contact-icon' /></a><a href="https://wa.me/+23278195584" target="_blank" rel="noopener noreferrer"><FaWhatsapp size={25} className='contact-icon' /></a>+232 78 195584
              </p>
              <p className='mt-4'></p>
              <h3>Follow Us</h3>
              <p className=' text-content'><a href="https://www.facebook.com/Integrity-Solutions-102699625638281" target="_blank" rel="noopener noreferrer" className='no-style-dark'><FaFacebook size={30} className='contact-icon facebook' />facebook</a></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
