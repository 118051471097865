import React from 'react'
import airFreight from '../assets/air-freight.jpg';
import seaFreight from '../assets/sea-freight.jpg';
import trucking from '../assets/trucking.png';
import parking from '../assets/parking.png';
import specialCargo from '../assets/special-cargo.jpg';
import projectCargo from '../assets/project-cargo.jpg';
import warehousing from '../assets/warehousing.jpg';
import customFormalities from '../assets/custom-formalities.jpg';

const Services = () => {
  return (
    
    <div>
      <div className="container bg-light py-5 px-4">
        <h6>OUR SERVICES</h6>
        <h3>Comprehensive delivery solutions</h3>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={airFreight} className="card-img-top" alt="Air Freight"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Air Freight</h5>
                        <p className="card-text text-content">We provide cost-effective, on-time, 
                          and safe air cargo deliveries for all your shipments. Our seasoned and 
                          committed team is ready to provide top-notch guidance on air freight 
                          services tailored to perfectly match your requirements. 
                          Our services extend to destinations worldwide. We proudly serve 
                          as Appointed Cargo agent for Turkish Cargo in Sierra Leone.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={seaFreight} className="card-img-top" alt="Sea Freight"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Sea Freight</h5>
                        <p className="card-text text-content">We take pride in our ability to 
                          provide worldwide shipping services for a wide range of cargo types 
                          originating from Asia, for global destinations. Our expertise 
                          extends to bulk beverage shipments utilizing Flexitanks, as well as the 
                          transportation of heavy machinery and specialized cargoes for 
                          project-specific needs. Whether you require the movement of goods across 
                          continents or the handling of unique and oversized items, our 
                          comprehensive shipping solutions have you covered. Trust us to deliver 
                          your cargo efficiently and securely to its destination, no matter the 
                          complexity of the task.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={trucking} className="card-img-top" alt="Trucking"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Trucking</h5>
                        <p className="card-text text-content">We take pride in delivering secure, 
                          cost-effective, and dependable trucking services. Our comprehensive trucking 
                          solutions encompass a diverse array of freight options, ensuring the 
                          efficient transportation of goods not only across the country but also to 
                          neighboring nations. Whether you require domestic or cross-border shipments, 
                          you can trust us to provide the reliable trucking services that meet your 
                          needs, while prioritizing the safety and timely delivery of your cargo. 
                          Your logistics challenges are our expertise, and we’re here to ensure your 
                          goods reach their destination seamlessly.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={parking} className="card-img-top" alt="Parking Services"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Packing Services</h5>
                        <p className="card-text text-content">Our packing expertise knows no bounds. 
                          We’re here to not only save you precious time but also guarantee the safe 
                          arrival of your items at their intended destination. Our specialization lies 
                          in the meticulous packing of delicate and high-value possessions, ranging 
                          from antiques, fine art and electronics, to crystal and porcelain. 
                          Your treasured items are in capable hands with our packing professionals, 
                          ensuring they reach their destination in pristine conditions.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={specialCargo} className="card-img-top" alt="Special Cargo"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Special Cargo</h5>
                        <p className="card-text text-content">Exceptional cargo demands exceptional 
                          solutions, and we are well-prepared to meet these challenges with the 
                          requisite training and expertise. Certain types of cargo defy conventional 
                          packaging and require us to think innovatively to provide the best solutions. 
                          Our expertise shines when it comes to handling a diverse range of goods, 
                          including fresh fruits, vegetables, frozen foods, and both hazardous and 
                          non-hazardous chemical products. We pride ourselves on our ability to find 
                          customized and efficient shipping methods, ensuring the safe and timely 
                          delivery of your unique and challenging cargo. With us, you can trust that 
                          your goods are in capable hands, thanks to our trained professionals who 
                          are well-versed in handling these specialized shipments.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={warehousing} className="card-img-top" alt="Warehouse & Distribution"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Warehouse & Distribution</h5>
                        <p className="card-text text-content">We’re committed to understanding your 
                          unique challenges and proactively anticipating your business and logistics 
                          requirements. This approach enables us to offer robust solutions that add 
                          significant value to your operations. Whether you need specialized warehousing, 
                          distribution center services, or any combination thereof, we’ve got you covered. 
                          Our tailored solutions are designed to not only meet your current needs but also 
                          adapt to the evolving demands of your business, ensuring seamless and efficient 
                          logistics support. Your success is our priority, and we’re here to provide the 
                          right solutions to help drive it forward.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={projectCargo} className="card-img-top" alt="Project Cargo"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Project Cargo</h5>
                        <p className="card-text text-content">Our Project Cargo Services are tailored to 
                          handle the transportation of heavy industrial equipment and oversized tools. 
                          These operations are intricate, demanding the utmost security, care, and 
                          attention to detail. At our core, we specialize in intricate planning, 
                          meticulous management, and flawless execution across the entire supply chain, 
                          all aimed at minimizing any potential challenges. Our comprehensive service 
                          lends invaluable support to various industries, including Oil & Gas, Energy, 
                          Construction, and Mining. We’re here to ensure the seamless transportation of 
                          your critical equipment, no matter the complexity.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 py-4">
                <div className="card">
                    <img src={customFormalities} className="card-img-top" alt="Custom Formalities"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Customs formalities & documentation</h5>
                        <p className="card-text text-content">Customs procedures are integral to the 
                          smooth import and export of goods. At ISL, we recognize the vital role customs 
                          play in international trade. To optimize the efficiency and cost-effectiveness 
                          of your freight, we offer a range of services designed to handle all the 
                          necessary procedures on your behalf. Our team of customs experts is dedicated 
                          to assisting you throughout the process, ensuring your goods are cleared 
                          seamlessly, without any complications or additional expenses. With ISL, 
                          you can rest assured that your imports and exports are managed with precision 
                          and expertise, allowing you to focus on your core business activities.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  )
}

export default Services