import React, { useState } from 'react';

function getFormData(form) {
  const elements = form.elements;
  let honeypot;

  const fields = Object.keys(elements).filter((k) => {
    if (elements[k].name === "honeypot") {
      honeypot = elements[k].value;
      return false;
    }
    return true;
  }).map((k) => {
    if(elements[k].name !== undefined) {
      return elements[k].name;
    } else if(elements[k].length > 0){
      return elements[k].item(0).name;
    }
  }).filter((item, pos, self) => {
    return self.indexOf(item) === pos && item;
  });

  const formData = {};
  fields.forEach((name) => {
    const element = elements[name];

    formData[name] = element.value;

    if (element.length) {
      const data = [];
      for (let i = 0; i < element.length; i++) {
        const item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses";
  formData.formGoogleSendEmail = form.dataset.email || "";

  return {data: formData, honeypot: honeypot};
}

const Quote = () => {

  const [buttonText, setButtonText] = useState('Submit');
  const [selectedOption, setSelectedOption] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = getFormData(form);
    const data = formData.data;

    if (formData.honeypot) {
      return false;
    }

    setButtonText('Please Wait...');

    const url = form.action;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        form.reset();
        setButtonText('Submit');
      }
    };

    const encoded = Object.keys(data).map((k) => {
      return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');

    xhr.send(encoded);
  }

  const handleSelectorChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    
    <div className='App-header'>
      <div className="jumbotron jumbotron-fluid quote bg-primary text-white text-center py-5">
      </div>
      
      <div className="container py-5 px-4">
        <h6>GET A QUOTE</h6>
        <h3>Start planning today</h3>
      </div>
      <div className="container px-4">
        <form
          onSubmit={handleSubmit}
          method="post"
          action="requestQuote.php"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Name"
                  id="name"
                  placeholder="Enter name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="Email"
                  id="email"
                  placeholder="Enter email"
                />
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Number"
                  id="number"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <select value={selectedOption} onChange={handleSelectorChange} className="form-select" name="Freight">
                  <option value="Air Freight">Air Freight</option>
                  <option value="Sea Freight">Sea Freight</option>
                </select>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Departure"
                  id="departure"
                  placeholder="Departure City"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Delivery"
                  id="delivery"
                  placeholder="Delivery City"
                />
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Weight"
                  id="weight"
                  placeholder="Weight"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Length"
                  id="length"
                  placeholder="Length"
                />
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Width"
                  id="width"
                  placeholder="Width"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="Height"
                  id="height"
                  placeholder="Height"
                />
              </div>
            </div>
          </div>

          <div className="py-4">
            <button type="submit" className="btn btn-primary" disabled={buttonText === 'Please Wait...'}>{buttonText}</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Quote