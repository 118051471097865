import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Quote from './pages/Quote';
import Services from './pages/Services';
import Contact from './pages/Contact';
import About from './pages/About';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Header/>
      <main role="main">
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quote" element={<Quote/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/about" element={<About/>} />
        </Routes>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
