import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'
import { FaWhatsapp, FaFacebook, FaPhone, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="text-muted py-5 px-3 bg-dark">
      <div className="container">
        <div className="row text-white">
          <div className="col-md-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/quote">Get Quote</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Contact Information</h5>
            <ul className="list-unstyled contact">
              <li><a href="mailto:info@integritysolutionsc.com" className='no-style'><FaEnvelope size={20} className='contact-icon' />info@integritysolutionsc.com</a></li>
              <li><a href="tel:+23278195584"><FaPhone size={20} className='contact-icon' /></a><a href="https://wa.me/+23278195584" target="_blank" rel="noopener noreferrer"><FaWhatsapp size={25} className='contact-icon' /></a>+232 78 195584</li>
              <li><a href="https://maps.app.goo.gl/qaf2MVqnVLAHajPM9"><FaMapMarkerAlt size={25} className='contact-icon' /></a>10 Dundas Street, Freetown, Sierra Leone.</li>
              <li>Branch Office:</li>
              <li>Freetown International Airport</li>
            </ul>
          </div>
          <div className="col-md-4">
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li><a href="https://www.facebook.com/Integrity-Solutions-102699625638281" target="_blank" rel="noopener noreferrer" className='no-style'><FaFacebook size={30} className='contact-icon facebook' />facebook</a></li>
            </ul>
          </div>
        </div>
        <p className="mb-1 text-white">© Integrity Solutions & Logistics 2024</p>
        <p className="mb-1 text-white">Designed by <a href="https://mackitechsolutions.com/">MackiTech Solutions</a></p>
      </div>
    </footer>
  );
};

export default Footer;
