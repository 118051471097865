import React from 'react'
import hannah from '../assets/hb.jpg';
import kadie from '../assets/ks.jpg';
import abubakar from '../assets/ak.jpg';
import vision from '../assets/vision.png';
import turkishCargo from '../assets/turkish-cargo.jpg';

const About = () => {
  return (
    
    <div>
      <div className="jumbotron jumbotron-fluid about bg-primary text-white text-center py-5">
      </div>
      <div className="container py-5 px-4">
        <div>
          <h6>ABOUT US</h6>
          <h3>Cheap, fast & safe</h3>
          <p className='text-content py-2'>
            Our primary focus lies in Import/Export, specializing in 
            Consolidation and Freight Forwarding services for both LCL 
            (Less than Container Load) and FCL (Full Container Load) 
            traffic to and from destinations across Africa, Asia, Europe, 
            and America. What sets us apart is our formidable management 
            team. Boasting over a decade of collective experience in the 
            Shipping and Logistics industry. Comprising well-trained and 
            highly motivated staff, holding freight management 
            certificates, we have the right resources to provide superior 
            services and tailored solutions for all your logistics needs. 
            Guided by a dynamic senior management team comprised of seasoned 
            professionals in Logistics, our young and energetic workforce 
            ensures you receive real-time updates and top-notch services. 
            Your logistics requirements are in capable hands with our dedicated team.
            <br/><br/><em>Your solution is with us!</em>
          </p>
          
        </div>
        
        <h3>Our Mission</h3>
        <p className='text-content py-2'>
          Integrity Solutions & Logistics, has the mission of providing the 
          necessary financial substance to sustain the company and its employees 
          and to meet customers’ requirements through high-quality service, cost 
          control, and the development of our human capital.
          </p>
        <img src={vision} alt="Vision" />
        <p></p>
        <h3>Our Vision</h3>
        <p className='text-content py-2'>
          It is the company’s vision to be a key partner in integrated logistics 
          in Sierra Leone, offering a higher quality of service.
          </p>
        <p></p>
        <h3>Our Team</h3>
        <p className='text-content py-2'>
          With our young and energetic team led by a dynamic senior management, 
          who are seasoned professionals in Shipping and Logistics to give an upfront update.
        </p>
        
        <section className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center py-2">Meet Our Team</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="team-member">
                  <img src={kadie} alt="Team Member 1"/>
                  <h4>Kadie SAWYERR</h4>
                  <p>General Manager</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="team-member">
                  <img src={abubakar} alt="Team Member 2"/>
                  <h4>Abubakar KOWA</h4>
                  <p>Head of Operations</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="team-member">
                  <img src={hannah}alt="Team Member 3"/>
                  <h4>Hannah BOIMA</h4>
                  <p>Sales/Customer Care Executive</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <p></p>
        <h3>Our Partners</h3>
        <img src={turkishCargo} alt="Turkish Cargo" />
        <p className='text-content py-2'>
          Integrity Solutions & Logistics is an 
          Appointed Agent for TURKISH CARGO in Sierra Leone.
        </p>
        <p></p>
      </div>
      

    </div>
  )
}

export default About