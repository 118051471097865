// ButtonComponent.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaExclamationTriangle } from 'react-icons/fa';

function ButtonComponent() {
  
  const [error, setError] = useState(false);
  const [trackingID, setTrackingID] = useState("");

  const track = () => {
    setError(false);
    //console.log(trackingID.trim())
    if (trackingID.trim() === "") {
      setError(true);
    } else {
      const parts = trackingID.trim().split("-");
      if (trackingID.trim()[3].toLowerCase() === 'u') {
        if (trackingID.trim().toLowerCase().startsWith("cmau")) {
          document.querySelector('#cma').submit();
        } else if (trackingID.trim().toLowerCase().startsWith("maeu")) {
            window.open(`https://www.maersk.com/tracking/${trackingID.trim()}`, "_blank", "noopener noreferrer");
        } else if (trackingID.trim().toLowerCase().startsWith("bmou")) {
          window.open(`https://www.capps.com/cgi-bin/publicUnitInfo2?UNIT=${trackingID.trim()}`, "_blank", "noopener noreferrer");
        } else if (trackingID.trim().toLowerCase().startsWith("medu")) {
          navigator.clipboard.writeText(trackingID.trim()).then(() => {
            window.open(`https://www.msc.com/en/track-a-shipment`, "_blank", "noopener noreferrer");
          })
        } else {
          setError(true);
        }
      } else if (parts.length === 2 && parts[1].length === 8 && !isNaN(Number(parts[1]))) {
        if (parts[0] === "706") {
          window.open(`https://www.kqcargo.com/en/track-and-trace/?awb=${trackingID.trim()}`, "_blank", "noopener noreferrer");
        } else if (parts[0] === "235") {
          window.open(`https://www.turkishcargo.com/en/online-services/shipment-tracking?quick=True&awbInput=${trackingID.trim()}`, "_blank", "noopener noreferrer");
        } else if (parts[0] === "020") {
          window.open(`https://www.lufthansa-cargo.com/eservices/etracking/awb-details/-/awb/020/${parts[1].trim()}?searchFilter=awb`, "_blank", "noopener noreferrer");
        } else if (parts[0] === "057" || parts[0] === "074") {
          window.open(`https://www.afklcargo.com/mycargo/shipment/detail/${trackingID.trim()}`, "_blank", "noopener noreferrer");
        } else if (parts[0] === "071") {
          document.querySelector('#et').submit();
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };

  const clearError = () => {
    setError(false);
  };

  const clear = () => {
    setError(false);
    setTrackingID("");
  };

  return (
    <div className='py-3 same-line'>
        {error && (
            <p className="bg-danger text-white" style={{ margin: "5px", padding: "5px 25px" }}>
                <FaExclamationTriangle className='contact-icon'/> Enter a valid tracking code.
            </p>
        )}
        <input
            type='text'
            value={trackingID}
            onChange={(e) => setTrackingID(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    track();
                } else if (e.key === "Escape") {
                    clear();
                } else {
                    clearError()
                }
            }}
            placeholder='Tracking Code'
            className='form-control tracking'
        />
        <form action="https://cargo.ethiopianairlines.com/index/CargoTrack" method="post" target="_blank" id='et'>
            <input name="AirwayBilNum" type="hidden" value={trackingID}/>
        </form>
        <form action="https://www.cma-cgm.com/ebusiness/tracking/search" method="post" target="_blank" id='cma'>
            <input name="SearchViewModel.Reference" type="hidden" value={trackingID}/>
            <input name="SearchViewModel.FromHome" type="hidden" value="true"/>
            <input name="SearchViewModel.SearchBy" type="hidden" value="Container"/>
            <input name="g-recaptcha-response" type="hidden"/>
        </form>
        <button className="btn btn-primary" onClick={track}>
            Track
        </button>
    </div>
    
  );
}

export default ButtonComponent;
