import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Add a custom CSS file for styling
import logo from '../../assets/logo-white.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="sticky-top">
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
        <div className="container">
          <Link className="navbar-brand" to="/" onClick={handleCloseMenu}>
          <div className='logo-title'><div><img src={logo} alt="Logo"  id="logo" className="d-inline-block align-text-top"/></div><div><p className='titleText'>
          INTEGRITY SOLUTIONS
        </p>
        <p className='secondText'>
          & LOGISTICS
        </p></div></div></Link>
          <button 
            className={`navbar-toggler ${isOpen ? 'open' : ''}`} 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded={isOpen ? "true" : "false"} 
            aria-label="Toggle navigation" 
            onClick={handleToggle}
          >
            <svg className={`ham hamRotate ham1 ${isOpen ? 'active' : ''}`} viewBox="0 0 100 100" width="50">
              <path
                    className="line top"
                    d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
              <path
                    className="line middle"
                    d="m 30,50 h 40" />
              <path
                    className="line bottom"
                    d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
            </svg>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link home" to="/" onClick={handleCloseMenu}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services" onClick={handleCloseMenu}>Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about" onClick={handleCloseMenu}>About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact" onClick={handleCloseMenu}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
