import React from 'react'
import { Link } from 'react-router-dom';
import airFreight from '../assets/air-freight.jpg';
import seaFreight from '../assets/sea-freight.jpg';
import trucking from '../assets/trucking.png';
import about from '../assets/about.jpg';
import ButtonComponent from '../components/buttonComponent';

const Home = () => {

  return (
    
    <div>
      <div className="jumbotron jumbotron-fluid bg-primary text-white text-center py-5">
      </div>
      <div className="container py-4 px-4">
        <h6>INTEGRITY SOLUTIONS & LOGISTICS</h6>
        <h3>The reliable logistics center</h3>
        <div className='py-3 same-line'>
          <ButtonComponent/>
          <div className='py-3'>
            <Link
              className="btn btn-primary"
              to="/quote"
            >
              Get Quote
            </Link>
          </div>
        </div>
      </div>
      
      <div className="container bg-light py-5 px-4">
        <h6>OUR SERVICES</h6>
        <h3>Comprehensive delivery solutions</h3>
        <div className="row">
            <div className="col-md-4 py-4">
                <div className="card">
                    <img src={airFreight} className="card-img-top" alt="Air Freight"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Air Freight</h5>
                        <p className="card-text text-content">Cost-effective, on-time and safe deliveries.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 py-4">
                <div className="card">
                    <img src={seaFreight} className="card-img-top" alt="Sea Freight"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Sea Freight</h5>
                        <p className="card-text text-content">Worldwide shipping services for a wide range of cargo types.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 py-4">
                <div className="card">
                    <img src={trucking} className="card-img-top" alt="Trucking"/>
                    <div className="card-body px-4 py-5">
                        <h5 className="card-title">Trucking</h5>
                        <p className="card-text text-content">Ensuring efficient transportation of goods across the country and with neighboring nations.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='py-2'></div>
        <Link
          className="btn btn-primary"
          to="/services"
        >
          Learn more
        </Link>
      </div>

      <div className="container py-5 px-4">
        <h6>ABOUT US</h6>
        <div className="row">
          <div className="col-md-6 py-3">
          <h3>Cheap, fast & safe</h3>
          <p className='text-content py-2'>
            Our primary focus lies in Import/Export. We 
            specialize in Consolidation and Freight 
            Forwarding services for both LCL (Less than Container Load) 
            and FCL (Full Container Load) traffic to and 
            from destinations across Africa, Asia, Europe, and America.
          </p>
          
        </div>
        <div className="col-md-6 py-3">
          <img src={about} alt="About Us" />
          <div className='py-2'></div>
        </div>
        </div>
        <Link
          className="btn btn-primary"
          to="/about"
        >
          Learn more
        </Link>
      </div>
      

    </div>
  )
}

export default Home